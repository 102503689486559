<template>
    <div class="flex flex-col flex-grow-0 justify-center text-center">
        <!-- <dv-charts :option="option1" class="flex-1" /> -->
        <div
            id="myChart5"
            class="p-2"
            :style="{ width: '100%', height: '100%' }"
        ></div>
    </div>
</template>

<script>
import echarts from "echarts";
import axios from "axios";
import config from "../../../config";
export default {
    data() {
        return {};
    },
    mounted() {
        let thi = this;
        let type = "日";
        thi.getData(type);

        // 间歇定时变参
        let arr = ["月", "日"];
        let i = 0;
        setInterval(() => {
            show();
        }, config.time);
        function show() {
            type = arr[i];
            thi.getData(type);
            i = i + 1;
            if (i >= arr.length) {
                i = 0;
            }
        }
    },
    methods: {
        getData(type) {
            var myChart = echarts.init(document.getElementById("myChart5"));

            axios
                .post(config.url + "sales", {
                    type: type,
                })
                .then((res) => {
                    let arrSs1 = [];
                    let arrSl = [];
                    let xarr = [];
                    let titleTxt = `${type}销售趋势`;
                    let info = res.data.data.reverse();

                    info.map((el) => {
                        arrSs1.push(parseInt(Number(el.ss1).toFixed(0)));
                        arrSl.push(el.sl);
                        let rqStr = el.rq;
                        let rqArr = [];
                        rqArr = rqStr.split("-");

                        if (type == "日") {
                            xarr.push(`${rqArr[2]}日`);
                        } else if (type == "月") {
                            xarr.push(`${rqArr[1]}月`);
                        }
                    });

                    let option = {
                        title: {
                            text: titleTxt,
                            left: "center",
                            textStyle: {
                                color: "#FFF",
                                fontSize: 14,
                            },
                        },
                        grid: {
                            top: "30%",
                            bottom: "18%",
                            left: "0",
                            right: "0",
                        },
                        xAxis: {
                            data: xarr,
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: "#5ec6cc",
                                },
                            },
                            axisLabel: {
                                fontSize: 10,
                                fontWeight: 700,
                            },
                        },
                        yAxis: [
                            {
                                axisLabel: {
                                    show: false,
                                },
                                axisTick: {
                                    show: false,
                                },
                                axisLine: {
                                    show: false,
                                },
                                splitLine: {
                                    show: false,
                                },
                            },
                        ],
                        series: [
                            {
                                // data: arrSl,
                                data: arrSs1,
                                type: "line",
                                smooth: true,
                                barWidth: 5,
                                label: {
                                    show: true,
                                    // formatter: "{c}件",
                                    formatter: "{c}元",
                                    color: "#b7fd72",
                                    fontSize: 8,
                                    fontWeight: "bold",
                                },
                                itemStyle: {
                                    color: "#fff",
                                },
                                lineStyle: {
                                    color: "#5ec6cc",
                                },
                            },
                        ],
                    };
                    myChart.setOption(option);
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error);
                });
        },
    },
};
</script>
