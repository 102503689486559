<template>
	<div class="w-full h-full bg-gray-900 relative text-white">
		<dv-full-screen-container id="dv-full-screen-container">
			<TopHeader />
			<dv-border-box-8 style="height: 91%">
				<div class="flex flex-row justify-between p-2 h-full">
					<dv-border-box-13 style="width: 27%">
						<div class="p-4 w-full h-full">
							<LeftChart1 />
							<LeftChart2 />
							<LeftChart3 />
						</div>
					</dv-border-box-13>
					<div class="flex-1 ml-4" style="margin-right: 1rem; width: 46%">
						<mid-center style="height: 70%" />
						<mid-bar style="height: 30%" />
					</div>
					<div class="flex flex-col justify-between p-1" style="width: 27%">
						<RightChart1 />
						<RightChart2 />
						<RightChart3 />
					</div>
				</div>
			</dv-border-box-8>
		</dv-full-screen-container>
	</div>
</template>

<script>
	// 使用DataV
	import TopHeader from "./datav/TopHeader.vue";

	import LeftChart1 from "./datav/LeftChart1.vue";
	import LeftChart2 from "./datav/LeftChart2.vue";
	import LeftChart3 from "./datav/LeftChart3.vue";

	import MidCenter from "./datav/MidCenter.vue";
	import MidBar from "./datav/MidBar.vue";

	import RightChart1 from "./datav/RightChart1.vue";
	import RightChart2 from "./datav/RightChart2.vue";
	import RightChart3 from "./datav/RightChart3.vue";

	export default {
		name: "DataView",
		components: {
			LeftChart1,
			LeftChart2,
			LeftChart3,
			MidCenter,
			MidBar,
			TopHeader,
			RightChart1,
			RightChart2,
			RightChart3,
		},
	};
</script>

, RightChart1
<style lang="less">
	#dv-full-screen-container {
		background-image: url("../assets/bg.png");
		background-size: 100% 100%;
		box-shadow: 0 0 3px blue;
		display: flex;
		flex-direction: column;
	}
</style>
