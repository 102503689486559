<template>
    <div
        class="flex flex-col flex-grow-0 justify-center text-center"
        style="height: 33%"
    >
        <div style="font-size: 14px; padding: 8px 0 4px; font-weight: 700">
            销售排名
        </div>
        <dv-scroll-board :config="config" class="flex-1" />
    </div>
</template>

<script>
import axios from "axios";
import config from "../../../config";
export default {
    name: "LeftChart3",
    data() {
        return {
            config: {
                header: ["姓名", "门店", "金额"],
                headerHeight: 22,
                columnWidth: [30, 65, 115, 105],
                waitTime: 5000,
                index: true,
                align: ["center"],
                data: [["", "", ""]],
            },
        };
    },
    created() {
        this.getData();
        setInterval(() => {
            this.getData();
        }, config.time);
    },
    methods: {
        getData() {
            axios
                .post(config.url + "salesperson_sales")
                .then((res) => {
                    let arr = [];
                    let info = res.data.data;
                    info.map((el, i) => {
                        // arr.push([i + 1, el.xsman, Number(el.ss1).toFixed(0)]);
                        arr.push([
                            `<div style="font-size:10px;">${i + 1}</div>`,
                            `<div style="font-size:10px;">${el.xsman}</div>`,
                            `<div style="font-size:10px;">${el.kf}</div>`,
                            `<div style="font-size:10px;">${Number(
                                el.ss1
                            ).toFixed(0)}</div>`,
                        ]);
                    });
                    this.config = {
                        header: [
                            "",
                            `<div style="font-size:10px;font-weight:700;">姓名</div>`,
                            `<div style="font-size:10px;font-weight:700;">门店</div>`,
                            `<div style="font-size:10px;font-weight:700;">金额</div>`,
                        ],
                        headerHeight: 22,
                        columnWidth: [30, 65, 115, 105],
                        waitTime: 5000,
                        indexHeader: "",
                        align: ["center", "center", "center", "center"],
                        data: arr,
                    };
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error);
                });
        },
    },
};
</script>

<style lang="less">
.left-chart-3 {
    width: 100%;
    height: 33%;
    display: flex;
    flex-direction: column;

    .lc3-header {
        height: 20px;
        line-height: 20px;
        font-size: 16px;
        text-indent: 20px;
        margin-top: 10px;
    }

    .lc3-details {
        height: 40px;
        font-size: 16px;
        display: flex;
        align-items: center;
        text-indent: 20px;

        span {
            color: #096dd9;
            font-weight: bold;
            font-size: 35px;
            margin-left: 20px;
        }
    }

    .lc3-chart {
        flex: 1;
    }
}
</style>
