<template>
    <dv-border-box-9
        class="flex flex-col flex-grow-0 justify-center text-center"
        style="height: 32%"
    >
        <div
            id="myChart6"
            class="p-2"
            :style="{ width: '100%', height: '100%' }"
        ></div>
    </dv-border-box-9>
</template>

<script>
import echarts from "echarts";
import axios from "axios";
import config from "../../../config";
import color from "../../../color";
export default {
    name: "LeftChart1",
    data() {
        return {
            gtArr: ["钻石柜", "黄金柜"],
        };
    },
    mounted() {
        this.getGt();

        // 间歇定时变参，15s切换一次
        let thi = this;
        let i = 1;
        let gtStr = "";
        setInterval(() => {
            show();
        }, 15000);

        function show() {
            gtStr = thi.gtArr[i];
            thi.getData(gtStr);
            i = i + 1;
            if (i >= thi.gtArr.length) {
                i = 0;
            }
        }
    },
    methods: {
        getGt() {
            axios
                .post(config.url + "gt")
                .then((res) => {
                    let arr = [];
                    let gtStr = "";
                    if (res.data.data) {
                        res.data.data.map((el) => {
                            arr.push(el);
                        });
                    }
                    this.gtArr = arr;
                    gtStr = arr[0];
                    this.getData(gtStr);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getData(gtStr) {
            var myChart = echarts.init(document.getElementById("myChart6"));
            axios
                .post(config.url + "shape_percentage", {
                    gt: gtStr,
                })
                .then((res) => {
                    let arr = [];
                    if (res.data.data) {
                        res.data.data.map((el) => {
                            arr.push({
                                name: el.mc,
                                value: el.sl,
								price: el.ss1,
								zb: el.zb
                            });
                        });
                    } else {
                        arr = [
                            {
                                name: "戒指",
                                value: 0,
								price: 0,
								zb: 0
                            },
                            {
                                name: "项链",
                                value: 0,
								price: 0,
								zb: 0
                            },
                        ];
                    }

                    let option = {
                        color: color,
                        title: {
                            text: `款式统计(${gtStr})`,
                            left: "10",
                            textStyle: {
                                color: "#FFF",
                                fontSize: 14,
                            },
                        },
                        series: [
                            {
                                type: "pie",
                                data: arr,
                                top: 10,
                                radius: "60%",
                                labelLine: {
                                    length: 5,
                                    length2: 5,
                                },
                                label: {
                                    fontWeight: "bold",
                                    fontSize: 8,
                                    position: "outside",
                                    // formatter: "{b}:{d}%",
                                    formatter: (params) => {
                                        let lableName = "";
										lableName = `${params.data.name}:${Number(params.data.zb).toFixed(0)}%,${params.data.price}元`
                                        return lableName;
                                    },
                                },
                            },
                        ],
                    };
                    myChart.setOption(option);
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error);
                });
        },
    },
};
</script>

<style lang="less">
.left-chart-1 {
    width: 100%;
    height: 33%;
    display: flex;
    flex-grow: 0;
    flex-direction: column;

    .lc1-header {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        margin-bottom: 20px;
    }

    .lc1-chart {
        flex: 1;
    }
}
</style>
