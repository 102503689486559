let color = [
  "#816af1",
  "#e6d92f",
  "#e24971",
  "#5ec6cc",
  "#eef7ac",
  "#53a9fc",
  "#da8625",
  "#5180ff",
  "#EE82EE",
  "#1E90FF",
  "#2E8B57",
  "#DAA520",
  "#7B68EE",
  "#9a60b4",
  "#fc8452",
  "#73c0de",
  "#5470c6",
];
export default color;
