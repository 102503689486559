<template>
    <div class="charts" style="width: 100%; height: 33%">
        <div id="myChart" :style="{ width: '100%', height: '100%' }"></div>
        <dv-decoration-2 style="width: 200px; height: 5px" />
    </div>
</template>

<script>
import echarts from "echarts";
import axios from "axios";
import config from "../../../config";
import color from "../../../color";
export default {
    data() {
        return {};
    },
    mounted() {
        this.getData();
        setInterval(() => {
            this.getData();
        }, config.time);
    },
    methods: {
        getData() {
            var myChart = echarts.init(document.getElementById("myChart"));

            axios
                .post(config.url + "area_sales")
                .then((res) => {
                    let arrSs1 = [],
                        arrSl = [],
                        arrName = [];
                    res.data.data.map((el) => {
                        arrName.push(el.name);
                        arrSs1.push(parseInt(Number(el.value.ss1).toFixed(0)));
                        arrSl.push(parseInt(Number(el.value.sl).toFixed(0)));
                    });

                    let option = {
                        color: color,
                        title: {
                            text: "区域排行",
                            left: "center",
                            textStyle: {
                                color: "#FFF",
                                fontSize: 14,
                            },
                        },
                        grid: {
                            top: "28%",
                            bottom: "18%",
                            left: "10",
                            right: "10",
                        },
                        legend: {
                            data: ["销售额", "件数"],
                        },
                        xAxis: {
                            data: arrName,
                            // show: false,
                            axisLine: {
                                show: false,
                                lineStyle: {
                                    color: "#fff",
                                },
                            },
                            axisLabel: {
                                fontSize: 10,
                                fontWeight: "bold",
                            },
                        },
                        yAxis: [
                            {
                                type: "value",
                                axisLabel: {
                                    show: false,
                                },
                                axisTick: {
                                    show: false,
                                },
                                axisLine: {
                                    show: false,
                                },
                                splitLine: {
                                    show: false,
                                },
                            },
                            {
                                type: "value",
                                position: "right",
                                axisLabel: {
                                    show: false,
                                },
                                axisTick: {
                                    show: false,
                                },
                                axisLine: {
                                    show: false,
                                },
                                splitLine: {
                                    show: false,
                                },
                            },
                        ],
                        series: [
                            {
                                name: "销售额/万元",
                                data: arrSs1,
                                // data: [227167, 15000, 156842],
                                type: "bar",
                                label: {
                                    show: true,
                                    formatter: "{c}元",
                                    fontSize: 8,
                                    color: "#fff",
                                    fontWeight: "bold",
                                    position: "insideBottom",
                                },
                            },
                            {
                                name: "件数/件",
                                data: arrSl,
                                // data: [443, 99, 800],
                                yAxisIndex: 1,
                                type: "line",
                                label: {
                                    show: true,
                                    formatter: "{c}件",
                                    fontSize: 8,
                                    fontWeight: "bold",
                                },
                                lineStyle: {
                                    lineWidth: "2",
                                },
                            },
                        ],
                    };
                    // console.log(option);

                    myChart.setOption(option);
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error);
                });
        },
    },
};
</script>
