<template>
    <dv-border-box-1 class="relative">
        <div
            class="flex flex-col items-center"
            style="padding: 0 1rem; height: 100%"
        >
            <div
                class="flex justify-between items-center w-full midCenterTitle"
                style="margin-top: 10px"
            >
                <dv-decoration-1 style="width: 100%; height: 30px" />
                <span class="midCenterTitleTxt text-xl">今日销售额</span>
            </div>
            <dv-digital-flop
                :config="config"
                style="width: 100%; height: 60px"
            />
            <div
                class="flex justify-between items-center w-full flex-1"
                style="height: 100%; position: absolute; padding: 0px 20px"
            >
                <div
                    class="text-xs leading-normal"
                    style="text-align: left;width；20%;"
                >
                    <p>新增会员：{{ info.xzhy }}人</p>
                    <p>复购会员：{{ info.fghy }}%</p>
                    <p>活跃会员：{{ info.xfhy }}人</p>
                </div>

                <div
                    class="text-xs leading-normal"
                    style="text-align: right;width；20%;"
                >
                    <p>连单率：{{ info.ldl }}%</p>
                    <p>客单价：￥{{ Number(info.kdj).toFixed(0) }}</p>
                    <p>折扣率：{{ info.zkl }}%</p>
                </div>
            </div>
            <dv-active-ring-chart
                :config="data"
                style="width: 100%; height: calc(100% - 100px - 80px)"
            />
            <div
                class="flex flex-col justify-center items-center"
                style="
                    width: calc(100% - 40px);
                    position: absolute;
                    bottom: 0;
                    padding: 0px 20px;
                "
            >
                <dv-border-box-7
                    style="
                        padding: 20px 10px;
                        width: 100%;
                        position: absolute;
                        bottom: 16px;
                    "
                >
                    <MarqueeTips
                        :content="message"
                        style="font-size: 14px"
                        :speed="80"
                /></dv-border-box-7>
            </div>
        </div>
    </dv-border-box-1>
</template>

<script>
import MarqueeTips from "vue-marquee-tips";
import axios from "axios";
import config from "../../../config";

export default {
    components: { MarqueeTips },
    data() {
        return {
            config: {
                number: [12481.2],
                toFixed: 2,
                content: "{nt}元",
                rowGap: 1,
                style: {
                    fontSize: 40,
                },
            },
            info: {},
            data: {
                radius: "70%",
                activeRadius: "80%",
                activeTimeGap: 5000,
                lineWidth: 50,
                digitalFlopStyle: {
                    fontSize: 14,
                    fill: "#fff",
                },
                data: [
                    {
                        name: "素金类",
                        value: 55,
                    },
                    {
                        name: "非素类",
                        value: 120,
                    },
                    {
                        name: "非白类",
                        value: 78,
                    },
                ],
            },
            message:
                "【xx店】林伟斌 销售 <span style='color:#FF0000'>¥1000</span>",
        };
    },
    created() {
        this.getInfo();
        this.getCharts();
        this.getTimeMsg();

        setInterval(() => {
            this.getInfo();
            this.getCharts();
            this.getTimeMsg();
        }, config.time);
    },
    methods: {
        getInfo() {
            axios
                .post(config.url + "today_count")
                .then((res) => {
                    this.info = res.data.data;
                    if (res.data.data.jrxs == 0) {
                        this.info.jrxs = 0;
                    }
                    this.config = {
                        number: [this.info.jrxs],
                        toFixed: 2,
                        content: "{nt}元",
                        rowGap: 1,
                        style: {
                            fontSize: 40,
                        },
                    };
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error);
                });
        },
        getCharts() {
            axios
                .post(config.url + "sale_category")
                .then((res) => {
                    let arr = [];
                    res.data.data.map((el) => {
                        arr.push({
                            name: el.pl,
                            value: el.sl,
                        });
                    });

                    this.data = {
                        radius: "70%",
                        activeRadius: "80%",
                        activeTimeGap: 5000,
                        lineWidth: 20,
                        digitalFlopStyle: {
                            fontSize: 14,
                            fill: "#fff",
                        },
                        data: arr,
                    };
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error);
                });
        },
        getTimeMsg() {
            axios
                .post(config.url + "real_time")
                .then((res) => {
                    let arr = [],
                        str = "";
                    res.data.data.map((el) => {
                        str = `【${el.demp_na}】${el.xsman}销售 <span style='color:#FF0000'>￥${el.amtss}</span>`;
                        arr.push(str);
                    });
                    this.message = arr.join(" ");
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error);
                });
        },
    },
};
</script>

<style scoped>
.leftText {
    position: absolute;
    left: 10px;
    bottom: 26%;
}
.rightText {
    position: absolute;
    right: 10px;
    bottom: 26%;
}
.midCenterTitle {
    position: relative;
}
.midCenterTitleTxt {
    /* font-size: 24px; */
    font-weight: bold;
    color: #c5ff3d;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
</style>
