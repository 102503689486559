<template>
	<div class="text-xl text-center flex justify-between items-center" style="height: 40px; line-height: 40px">
		<dv-decoration-3 style="width: 20%; height: 20px" />
		<dv-decoration-8 style="width: 20%; height: 20px" />
		<dv-decoration-11 style="width: 20%; height: 40px" class="text-base">
			{{config.title}}
		</dv-decoration-11>
		<dv-decoration-8 :reverse="true" style="width: 20%; height: 20px" />
		<dv-decoration-3 style="width: 20%; height: 20px" />
	</div>
</template>

<script>
	import config from "../../../config";
	export default {
		data() {
			return {
				config
			}
		}
	};
</script>
