<template>
    <div class="charts" style="width: 100%; height: 33%">
        <div id="myChart2" :style="{ width: '100%', height: '100%' }"></div>
        <dv-decoration-2 style="width: 200px; height: 5px" />
    </div>
</template>

<script>
import echarts from "echarts";
import axios from "axios";
import config from "../../../config";

export default {
    name: "LeftChart2",
    data() {
        return {
            // 分页参数
            current_page: 1,
            last_page: "",
            isTime: true,
        };
    },
    mounted() {
        this.getData();
        if (this.isTime) {
            setInterval(() => {
                this.getTimeFun();
            }, 8000);
        } else {
            setInterval(() => {
                this.getTimeFun();
            }, config.time);
        }
    },
    methods: {
        // 间歇定时变参
        getTimeFun() {
            this.current_page++;
            if (this.current_page > this.last_page) {
                this.current_page = 1;
            }
            this.getData();
        },
        getData() {
            var myChart = echarts.init(document.getElementById("myChart2"));
            let colorArr = [
                "#816af1",
                "#e24971",
                "#53a9fc",
                "#2E8B57",
                "#fc8452",
            ];

            axios
                .post(config.url + "store_sales", {
                    page: this.current_page,
                    perPage: 5,
                })
                .then((res) => {
                    this.current_page = res.data.meta.current_page;
                    this.last_page = res.data.meta.last_page;
                    // console.log(res.data.meta.current_page);
                    // console.log(res.data.meta.last_page);
                    if (this.current_page == this.last_page) {
                        this.isTime = false;
                    }
                    let yarr = [];
                    let arr = [];
                    res.data.data.map((el, i) => {
                        yarr.push(el.demp_na);
                        // arr.push(parseInt(Number(el.ss1).toFixed(0)));
                        arr.push({
                            value: parseInt(Number(el.ss1).toFixed(0)),
                            itemStyle: {
                                color: colorArr[i],
                            },
                        });
                    });

                    let option = {
                        title: {
                            text: "门店排行",
                            left: "center",
                            top: 5,
                            textStyle: {
                                color: "#FFF",
                                fontSize: 14,
                            },
                        },
                        grid: {
                            left: 60,
                            right: 0,
                            top: "22%",
                            bottom: "10%",
                        },
                        xAxis: {
                            type: "value",
                            axisLabel: {
                                show: false,
                            },
                            axisTick: {
                                show: false,
                            },
                            axisLine: {
                                show: false,
                            },
                            splitLine: {
                                show: false,
                            },
                        },
                        yAxis: {
                            type: "category",
                            data: yarr,
                            inverse: true,
                            axisLine: {
                                show: false,
                                lineStyle: {
                                    color: "#fff",
                                },
                            },
                            axisLabel: {
                                color: "#FFF",
                                fontSize: 10,
                                fontWeight: 700,
                            },
                        },
                        series: [
                            {
                                data: arr,
                                type: "bar",
                                label: {
                                    show: true,
                                    formatter: "{c}元",
                                    fontSize: 8,
                                    color: "#fff",
                                    fontWeight: "bold",
                                },
                            },
                        ],
                    };
                    // console.log(option);

                    myChart.setOption(option);
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error);
                });
        },
    },
};
</script>
