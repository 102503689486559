<template>
    <dv-border-box-12
        class="flex flex-col flex-grow-0 justify-center text-center"
        style="height: 32%"
    >
        <div
            id="myChart7"
            class="p-2"
            :style="{ width: '100%', height: '100%' }"
        ></div>
    </dv-border-box-12>
</template>

<script>
import echarts from "echarts";
import axios from "axios";
import config from "../../../config";
import color from "../../../color";

export default {
    name: "LeftChart2",
    data() {
        return {};
    },
    mounted() {
        this.getData();
        setInterval(() => {
            this.getData();
        }, config.time);
    },
    methods: {
        getData() {
            var myChart = echarts.init(document.getElementById("myChart7"));
            axios
                .post(config.url + "recycle_category")
                .then((res) => {
                    let arr = [];
                    if (res.data.data) {
                        res.data.data.map((el) => {
                            arr.push({
                                name: el.pl,
                                value: el.sl,
                            });
                        });
                    } else {
                        arr = [
                            {
                                name: "黄金类",
                                value: 0,
                            },
                            {
                                name: "K金类",
                                value: 0,
                            },
                            {
                                name: "3D类",
                                value: 0,
                            },
                        ];
                    }

                    let option = {
                        color: color,
                        title: {
                            text: "旧料回收",
                            left: "10",
                            textStyle: {
                                color: "#FFF",
                                fontSize: 14,
                            },
                        },
                        series: [
                            {
                                type: "pie",
                                data: arr,
                                top: 10,
                                radius: ["30%", "60%"],
                                labelLine: {
                                    length: 5,
                                    length2: 5,
                                },
                                label: {
                                    fontWeight: "bold",
                                    fontSize: 8,
                                    position: "outside",
                                    formatter: "{b}:{d}%",
                                    // formatter: (params) => {
                                    //     let lableName = "";
                                    //     if (params.name.length > 4) {
                                    //         lableName = `${params.name.substring(
                                    //             0,
                                    //             5
                                    //         )}...`;
                                    //     } else {
                                    //         lableName = `${params.name.substring(
                                    //             0,
                                    //             5
                                    //         )}`;
                                    //     }
                                    //     return lableName;
                                    // },
                                },
                            },
                        ],
                    };
                    myChart.setOption(option);
                })
                .catch(function (error) {
                    // 请求失败处理
                    console.log(error);
                });
        },
    },
};
</script>

<style lang="less">
.left-chart-1 {
    width: 100%;
    height: 33%;
    display: flex;
    flex-grow: 0;
    flex-direction: column;

    .lc1-header {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        margin-bottom: 20px;
    }

    .lc1-chart {
        flex: 1;
    }
}
</style>
