export default {
	// 测试地址
	// url: "http://www.xxy.xyz",
	
	// 鑫玺云
	url: "https://bigapitest.xinxyun.com/api/big_screen/index/",
	title:'鑫玺云数据看板',
	
	// 南洋
	// url: "https://api.nyzbjt.com/api/big_screen/nan_yang/",
	// title:'南洋珠宝集团',

	time: 60000,
	
	/*
		尺寸：960*540
	*/
};
